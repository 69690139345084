import { IVenue } from "../Venue"

export interface LocalizedField {
  fieldName: string 
  countryCode: string 
}

export enum COUNTRY_CODES {
    FINLAND = 'fi',
    MALTA = 'mt'
}

export enum COUNTRY_NAMES {
    FINLAND = 'finland',
    MALTA = 'malta'
}


  export const getBenefitCountrySpecificFields = ({venueCountryName}:{ venueCountryName: string}): LocalizedField[] => {
    const countryName = venueCountryName?.toLowerCase()

    if (countryName === COUNTRY_NAMES.FINLAND) {
      return [
        { fieldName: 'title', countryCode: COUNTRY_CODES.FINLAND }
      ];
    }
    return [];
  }