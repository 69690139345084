import { Instance, types } from 'mobx-state-tree'
import i18n from '../../i18n'

const LocalizedTextContent = types.model('LocalizedTextContent', {
  title: types.string,
  description: types.maybe(types.string),
  shortDescription: types.string,
})

type ILocalizedTextContent = Instance<typeof LocalizedTextContent>

interface TextMap {
  get(key: string): ILocalizedTextContent | undefined
  keys(): IterableIterator<string>
}

type TextMapType = {
  get(key: string): TextMap | undefined
}

interface GetAvailableLanguagesParams {
  textMap: TextMapType | undefined
  textType?: 'active' | 'passive'
}

interface GetLocalizedTextParams {
  textMap: TextMapType | undefined
  textType: 'active' | 'passive'
  field: 'title' | 'shortDescription' | 'description'
  language?: string
}

export const getAvailableLanguages = ({ textMap, textType = 'active' }: GetAvailableLanguagesParams): string[] => {
  try {
    const typeMap = textMap?.get(textType)
    if (!typeMap) return ['en']
    
    return Array.from(typeMap.keys())
  } catch {
    return ['en']
  }
}

const getValidLanguageCode = (availableLanguages: string[], languageCode: string): string => {
  const validLanguageCode = availableLanguages.find(code => code === languageCode)
  return validLanguageCode || 'en'
}

export const getLocalizedText = ({ textMap, textType, field, language }: GetLocalizedTextParams): string => {
  const availableLanguages = getAvailableLanguages({ textMap, textType })
  const languageCode = getValidLanguageCode(availableLanguages, language || i18n.languages[0])

  try {
    const typeMap = textMap?.get(textType)
    if (!typeMap) return ''
    
    const localizedText = typeMap.get(languageCode)
    if (localizedText && localizedText[field]) {
      const fieldValue = localizedText[field]
      return fieldValue || ''
    }
    
    const englishText = typeMap.get('en')
    if (englishText && englishText[field]) {
      const fieldValue = englishText[field]
      return fieldValue || ''
    }
    
    return ''
  } catch {
    return ''
  }
}